import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'
import variables from '@/styles/variables'

const content = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 1030px;
  padding: 15px;
  width: 100%;
`

const sectionTitle = css`
  background-color: ${variables.BASE_BGC};
  border-color: ${variables.BASE_BDC};
  border-style: solid;
  border-width: 2px 2px 4px 2px;
  color: ${variables.BASE_BDC};
  font-family: Yusei Magic, sans-serif;
  margin-bottom: 75px;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    border-color: ${variables.BASE_BDC} transparent transparent transparent;
    border-style: solid;
    border-width: 16px 15px 0 15px;
    bottom: -16px;
    content: '';
    height: 0;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 0;
  }

  span {
    font-weight: 400;
    ${fontSize(25)}

    ${minMq('MD')} {
      ${fontSize(33)}
    }
  }
`

type Props = {
  title: string
}

export const SectionTitle = (props: Props): JSX.Element => {
  return (
    <header css={content}>
      <h2 css={sectionTitle}>
        <span>{props.title}</span>
      </h2>
    </header>
  )
}
