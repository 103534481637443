import { Section } from '@/components/molecules/Heading/Section'
import { style } from './drone.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export const Drone = (): JSX.Element => {
  return (
    <div css={style.content} id="drone">
      <Section>
        <section>
          <SectionTitle title="ドローン" />

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-09.png`} alt="" />
                  <p>ドローンで観光地を魅力的に撮影してほしい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>
                    専門のスタッフが現地へ行き撮影いたします。編集加工もお任せください。
                  </p>
                  <p css={style.text}>
                    当社保有のドローンで空撮を行います。4Kの高解像度動画も撮影可能です。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-10.png`} alt="" />
                  <p>ドローンの空撮を使った動画をWEBサイトで使いたい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>撮影から動画編集、WEBサイトへの組み込みまで。</p>
                  <p css={style.text}>
                    社屋の空撮や地域風景をドローンで空撮できます。撮影だけでなく動画の編集も可能です。イメージをお伝えください！
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="ドローン撮影するメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                ダイナミックで美しい映像で人を惹きつける
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                観光地や土地のPRができ、魅力が伝わりやすい
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>ドローン空撮</span>
              </h4>
              <p>
                観光地や風景の空撮・企業PRのための社屋空撮など承っております。4Kの高解像度撮影も可能です。
              </p>

              <table css={style.equipment}>
                <tbody>
                  <tr>
                    <th>撮影エリア</th>
                    <td>主要エリア：新潟県内</td>
                  </tr>
                  <tr>
                    <th>撮影機材</th>
                    <td>
                      DJI社 Inspire 2<br />
                      DJI社 Phantom 4 Pro
                    </td>
                  </tr>
                  <tr>
                    <th>解像度</th>
                    <td>HD、4K撮影対応可能</td>
                  </tr>
                  <tr>
                    <th>認証</th>
                    <td>国土交通省の包括申請許可取得済み</td>
                  </tr>
                  <tr>
                    <th>安全性・補償</th>
                    <td>
                      常時2名以上での対応
                      <br />
                      万一の事故に備え、保険加入済み
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="ギャラリー" />
            <div css={style.exampleList}>
              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-drone-01.png`}
                  alt={'懐かしい情景 夏井のハザ木'}
                />
                <p css={style.customer}>懐かしい情景 夏井のハザ木</p>
                <p css={style.exampleTitle}>空撮映像</p>
              </div>

              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-drone-02.png`}
                  alt={'水鳥の舞う初夏の福島潟'}
                />
                <p css={style.customer}>水鳥の舞う初夏の福島潟</p>
                <p css={style.exampleTitle}>空撮映像</p>
              </div>

              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-drone-03.png`}
                  alt={'水辺に建つ２つのスタジアム'}
                />
                <p css={style.customer}>水辺に建つ２つのスタジアム</p>
                <p css={style.exampleTitle}>空撮静止画</p>
              </div>
            </div>

            <div css={style.linkGallery}>
              <a
                href="http://vrains.pressmedia.co.jp/contents/gallery_drone/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <span>ギャラリーサイトを見る</span>
              </a>
            </div>
          </div>
        </section>
      </Section>
    </div>
  )
}
