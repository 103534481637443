import { Section } from '@/components/molecules/Heading/Section'
import { style } from './printing.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export const Printing = (): JSX.Element => {
  return (
    <div css={style.content} id="printing">
      <Section>
        <section>
          <SectionTitle title="印刷物作成" />

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-07.png`} alt="" />
                  <p>学校案内パンフレットを作りたい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>写真撮影からデザインまでお任せください。</p>
                  <p css={style.text}>
                    雰囲気に合ったデザインを提案させていただきます。
                    施設内の写真撮影も対応可能です。
                    <br />
                    学校案内だけでなく、会社案内・カタログ・パンフレットなど幅広く制作しています。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-09.png`} alt="" />
                  <p>商品紹介の冊子を作り直したい。 デザインもお願いしたい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>お客様のイメージに沿ったデザインを提供します。</p>
                  <p css={style.text}>
                    お客様とのヒアリングで制作物の目的やデザインイメージなどを共有します
                    <br />
                    目的に合ったデザインを提案させていただきます。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="印刷物作成のメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                長期保管に向いている。繰り返し読むことができ、記憶に残りやすい
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                情報が精査された信頼性の高いメディア
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                全体を一目で見ることができるので、情報が頭に入りやすい
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>印刷物のデザイン作成</span>
              </h4>
              <p>
                お客様とのヒアリングで制作物の目的やデザインイメージなどを共有します。
                <br />
                目的に合ったデザインを提案させていただきます。
              </p>

              <h4 css={style.method}>
                <span>幅広い印刷物に対応</span>
              </h4>
              <p>
                名刺・チラシ・ポスターなどのペラ物から、カタログや抄録集など100ページを超える大型の頁物まで、柔軟なチーム力で幅広い印刷物に対応いたします。
              </p>

              <h4 css={style.method}>
                <span>用途・活用例</span>
              </h4>
              <p>
                名刺・封筒・伝票／会社案内・学校案内／パンフレット／カタログ／研究会・学会の印刷物／チラシ・ポスター・DM／広告／周年誌・記念誌／広報誌／など
              </p>
            </div>
          </div>
        </section>
      </Section>
    </div>
  )
}
