import { Section } from '@/components/molecules/Heading/Section'
import { H } from '@/components/molecules/Heading/H'
import { Slider } from './slider'
import { style } from './description.style'

export const Description = (): JSX.Element => {
  return (
    <div css={style.wrapContent}>
      <div css={style.content}>
        <div css={style.wrapSection}>
          <Section>
            <section css={style.catchcopy}>
              <H>
                お客様の目的達成の
                <br />
                お手伝いができます
              </H>
              <p css={style.text}>
                私たちは、新潟を拠点としたデジタルコンテンツ・印刷物の制作会社です。WEBサイト制作・動画制作・ドローンや360度カメラでの撮影・CMSの導入・WEBシステム構築・印刷物の作成を通して、お客様のやりたいことを実現するお手伝いをします。
              </p>
            </section>
          </Section>
          <Slider />
        </div>
      </div>
    </div>
  )
}
