import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'
import variables from '@/styles/variables'

const content = css`
  margin: 0 auto;
  margin-top: 70px;
  max-width: 1030px;
  padding: 0 15px;
  width: 100%;
`
const workList = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 70px 0;
  justify-items: center;
  margin-bottom: 60px;

  ${minMq('MD')} {
    margin-bottom: 100px;
  }
`

const work = css`
  flex-basis: 50%;
  text-align: center;

  ${minMq('MD')} {
    flex-basis: 25%;
  }
`

const link = css`
  display: inline-block;
  position: relative;

  &::after {
    background-color: #000;
    bottom: 0;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.2s;
    width: 96px;
  }

  &.is-hover {
    &::after {
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
    }

    img {
      animation: rotation1 0.75s linear;
      transition: all 0.5s;
    }
  }

  @keyframes rotation1 {
    0% {
      transform: rotateY(0);
    }

    to {
      transform: rotateY(1turn);
    }
  }
`

const image = css`
  height: auto;
  margin: 0 auto;
  width: 130px;
`

const title = css`
  background: linear-gradient(transparent 60%, #fbef3c 0);
  flex-basis: 25%;
  font-weight: 700;
  margin: 15px 0 5px;
  padding: 0 2px 4px;
  text-align: center;
  ${fontSize(17)}

  ${minMq('MD')} {
    ${fontSize(22)}
  }
`

const detail = css`
  ${fontSize(14)}
  padding-bottom: 5px;
  text-align: center;
`

const boxCatch = css`
  background-color: ${variables.BASE_BGC};
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 1030px;
  padding: 20px;

  ${minMq('MD')} {
    margin-top: 20px;
    padding: 40px 20px;
  }
`

const worksCombination = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
`

const comment = css`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  margin-top: 15px;

  ${minMq('MD')} {
    display: block;
    flex-basis: 220px;
    margin-top: 0;
  }

  & > img {
    flex-basis: 40%;
    max-width: 128px;

    ${minMq('MD')} {
      flex-basis: auto;
      max-width: 100%;
    }
  }
`

const text = css`
  background-color: ${variables.SUB_C};
  border-radius: 10px;
  ${fontSize(14)}
  flex-basis: 60%;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  position: relative;
  text-align: justify;

  ${minMq('MD')} {
    flex-basis: auto;
    ${fontSize(18)}
  }

  &::after {
    border: 10px solid transparent;
    border-left-color: ${variables.SUB_C};
    content: '';
    height: 0;
    left: 100%;

    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0;

    ${minMq('MD')} {
      border-top-color: ${variables.SUB_C};
      flex-basis: auto;
      left: 50%;
      ${fontSize(18)}
      margin-left: -10px;
      top: 100%;
    }
  }
`

const figure = css`
  flex-basis: 100%;
  margin-bottom: 10px;

  ${minMq('MD')} {
    flex-basis: 700px;
    margin-bottom: 0;
  }
`

const worksWebsystem = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > img {
    width: 128px;

    ${minMq('MD')} {
      flex-basis: 160px;
      width: 100%;
    }
  }
`

const websystemComment = css`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  margin-top: 20px;

  ${minMq('MD')} {
    margin-top: 0;
    margin-left: 40px;
    flex-basis: 570px;
  }

  & > img {
    flex-basis: 220px;
    min-width: 128px;
  }
`
const WebsystemText = css`
  background-color: ${variables.SUB_C};
  border-radius: 10px;
  ${fontSize(14)}
  flex-basis: 310px;
  font-weight: 500;
  margin: 0;
  padding: 20px;
  position: relative;
  text-align: justify;

  ${minMq('MD')} {
    ${fontSize(18)}
  }

  &::after {
    border: 10px solid transparent;
    border-left-color: ${variables.SUB_C};
    content: '';
    height: 0;
    left: 100%;

    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0;
  }
`

export const style = {
  content,
  workList,
  work,
  link,
  image,
  title,
  detail,
  boxCatch,
  worksCombination,
  comment,
  text,
  figure,
  worksWebsystem,
  websystemComment,
  WebsystemText,
}
