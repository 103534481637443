import { Section } from '@/components/molecules/Heading/Section'
import { style } from './cms.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export const Cms = (): JSX.Element => {
  return (
    <div css={style.content} id="cms">
      <Section>
        <section>
          <SectionTitle title="CMS導入" />

          <SectionSubTitle title="CMSとは？" />

          <div css={style.whatsCms}>
            <img src={`${BASE_PATH}/home/img/whats-cms.png`} alt="CMSとは？" />
            <p>
              「Contents Management
              System（コンテンツ・マネジメント・システム）」の略で、WEBサイトのコンテンツ（テキストや画像、レイアウト情報など）を管理する仕組みです。
              この仕組みを用いることで、専門知識がなくてもWEBサイトの内容を簡単に更新することができます。
            </p>
          </div>

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-05.png`} alt="" />
                  <p>
                    自社サイトの更新を任されたけど、ホームページなんて触れたことがないからさっぱりわかならい！
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>専門知識は不要！ WEBサイトの更新が簡単に行えます。</p>
                  <p css={style.text}>
                    CMSは直観的な操作でWEBサイトの更新を行うことができます。難しい専門知識は不要です。ホームページに触れたことがない方も安心してお使いいただけます。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-01.png`} alt="" />
                  <p>
                    修正が発生する度に業者に手配……。
                    <br />
                    正直面倒だしお金もかかる……。
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>専門業者への手配が不要に！ コスト削減につながります。</p>
                  <p css={style.text}>
                    お客様自身でWEBサイトの更新が可能となるため、これまで業者へ発注していたWEBサイトの修正費用が削減できます。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-03.png`} alt="" />
                  <p>
                    急ぎの更新を業者に頼んだけど、即日公開は難しいと言われた。なんとかすぐに情報を公開したい！
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>
                    更新のタイミングが自由！ リアルタイムなWEBサイトの更新が実現。
                  </p>
                  <p css={style.text}>
                    お客様自身のタイミングで更新できるため、リアルタイムな情報発信が可能です。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-11.png`} alt="" />
                  <p>
                    休日だけど、この日に公開したい。
                    <br />
                    社員の誰かに休日出社してもらわないと……。
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>予約機能で自動公開！</p>
                  <p css={style.text}>公開日の日時を指定する予約機能もございます。</p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="CMSを導入するメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                ホームページの更新が容易
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                コストの削減
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                WEBサイトの更新頻度を高められる
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>オリジナルCMSの導入</span>
              </h4>
              <p>
                新着情報や採用情報など、頻繁に更新が行われるページに部分的にCMSを導入します。当社で独自開発したCMSを使用するため、柔軟な機能の拡張が可能です。
              </p>

              <h4 css={style.method}>
                <span>WordPressでのサイト構築</span>
              </h4>
              <p>
                WordPressを用いたWEBサイト全体のCMS構築を行います。
                <br />
                WordPressのカスタマイズにも対応しておりますので、お気軽にご相談ください。
              </p>
            </div>
          </div>
        </section>
      </Section>
    </div>
  )
}
