import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'

const content = css`
  margin-top: 70px;
  width: 100%;

  ${minMq('MD')} {
    margin-top: 140px;
  }
`

const exampleLink = css`
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.75;
  }
`

const exampleList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 60px 0;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 1030px;
  padding: 0 15px;
`

const exampleItem = css`
  flex-basis: 100%;

  ${minMq('MD')} {
    flex-basis: 33%;
  }

  img {
    height: auto;

    object-fit: cover;
    width: 100%;

    ${minMq('MD')} {
      max-height: 181px;
      max-width: 300px;
    }
  }
`

const customer = css`
  ${fontSize(18)}
  font-weight: 700;
  margin-top: 20px;

  ${minMq('MD')} {
    ${fontSize(20)}
  }
`

const title = css`
  color: #808080;
  ${fontSize(14)}
  font-weight: 500;
  margin-top: 10px;

  ${minMq('MD')} {
    ${fontSize(16)}
  }
`

export const style = {
  content,
  exampleLink,
  exampleList,
  exampleItem,
  customer,
  title,
}
