import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Works } from '@/types'
import { client } from '@/lib/microcmsClient'

export const useQueryWorks = (categoryName = '') => {
  const router = useRouter()
  const getWorks = async () => {
    const { contents } = await client.get({
      endpoint: 'works',
      queries: { filters: `category[equals]${categoryName}` },
    })
    return contents
  }

  return useQuery<Works[], Error>({
    queryKey: ['works', categoryName],
    queryFn: getWorks,
    staleTime: Infinity,
    onError: () => router.push('/'),
  })
}

export const useQueryWorkId = (workId: string) => {
  const getWork = async () => {
    const data = await client.get({ endpoint: 'works', contentId: workId })
    return data
  }

  return useQuery<Works, Error>({
    queryKey: ['work', workId],
    queryFn: getWork,
    staleTime: Infinity,
  })
}

export const useQueryPreiewWork = (workId: string, draftKey: string) => {
  const getWork = async () => {
    const data = await client.get({
      endpoint: 'works',
      contentId: workId,
      queries: { draftKey: draftKey },
    })
    return data
  }

  return useQuery<Works, Error>({
    queryKey: ['work', workId, draftKey],
    queryFn: getWork,
    staleTime: Infinity,
  })
}
