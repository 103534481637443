import Link from 'next/link'
import { useQueryWorks } from '@/hooks/useQueryWorks'
import { style } from './worksList.style'
import { Works } from '@/types'

type Props = {
  category: string
}

export const WorkList = ({ category }: Props): JSX.Element => {
  const { data: works, status } = useQueryWorks(category)
  if (status === 'loading') return <p>wait...</p>
  if (status === 'error') return <p>{'Error'}</p>

  const Item = (item: Works) => {
    return (
      <Link href={`/works/?work_id=${item.id}`}>
        <a css={style.exampleLink}>
          <div css={style.exampleItem}>
            <img src={item.eyecatch.url} alt={item.title} />
            <p css={style.customer}>{item.customer}</p>
            <p css={style.title}>{item.title}</p>
          </div>
        </a>
      </Link>
    )
  }

  return (
    <div css={style.exampleList}>
      {works?.map((work) => (
        <Item key={work.id} {...work} />
      ))}
    </div>
  )
}
