import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'
import variables from '@/styles/variables'

const content = css`
  padding-top: 140px;
  width: 100%;
`

const sectionBody = css`
  padding: 80px 0;
`

const yellow = css`
  background-color: ${variables.BASE_BGC};
`

const wrapCard = css`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1030px;
  padding: 0 15px;

  ${minMq('MD')} {
    padding: 0;
  }
`

const card = css`
  flex-basis: 100%;
  height: 100%;
  max-height: 420px;

  ${minMq('MD')} {
    flex-basis: 50%;
    max-width: 480px;
    padding: 0;
  }
`

const solve = css`
  background-color: ${variables.WHITE};
  border-radius: 10px;
  flex: 1 0 auto;
  height: 100%;
  margin-top: 20px;
  padding: 40px 25px;

  ${minMq('MD')} {
    height: 250px;
  }
`

const problem = css`
  align-items: center;
  display: flex;

  & > img {
    flex-shrink: 0;
    height: 90px;
    width: 90px;

    ${minMq('MD')} {
      height: 150px;
      width: 150px;
    }
  }
  & > p {
    flex: 1;
    font-size: 1.6rem;
    font-weight: 700;
    padding-left: 15px;
    ${fontSize(16)}

    ${minMq('MD')} {
      ${fontSize(20)}
    }
  }
`

const title = css`
  color: ${variables.BASE_BDC};
  font-family: Sawarabi Gothic, sans-serif;
  ${fontSize(16)}
  font-weight: 700;

  ${minMq('MD')} {
    ${fontSize(20)}
  }
`

const text = css`
  ${fontSize(16)}
  margin-top: 20px;
`

const merit = css`
  margin: 0 auto;
  max-width: 1030px;
  padding: 0 15px;
  ${fontSize(16)}

  ${minMq('MD')} {
    padding: 0;
  }

  li {
    margin-top: 25px;
    padding-left: 2em;
    text-indent: -2.22em;
  }
`

const badge = css`
  color: ${variables.SUB_C};
  height: auto;
  margin-right: 5px;
  vertical-align: middle;
  width: 30px;
`

const wrapMethod = css`
  margin: 0 auto;
  max-width: 1030px;
  padding: 0 15px;
`

const method = css`
  margin-top: 45px;
  span {
    background: linear-gradient(transparent 60%, ${variables.SUB_C} 0);
    display: inline-block;
    ${fontSize(20)}
    font-weight: 700;
    padding: 0 2px 4px;

    ${minMq('MD')} {
      ${fontSize(22)}
    }
  }

  & + p {
    ${fontSize(16)}
    line-height: 1.6;
    margin-top: 20px;
  }
`

const equipment = css`
  border-collapse: collapse;
  border-left: 1px solid ${variables.BTN_TXC};
  border-top: 1px solid ${variables.BTN_TXC};
  margin-top: 40px;
  width: 100%;

  th {
    background-color: ${variables.BASE_BGC};
    font-weight: 400;
    text-align: left;
    width: 180px;
    border-bottom: 1px solid ${variables.BTN_TXC};
    border-right: 1px solid ${variables.BTN_TXC};
    padding: 15px;
  }

  td {
    border-bottom: 1px solid ${variables.BTN_TXC};
    border-right: 1px solid ${variables.BTN_TXC};
    padding: 15px;
  }
`

export const style = {
  content,
  sectionBody,
  yellow,
  wrapCard,
  card,
  solve,
  problem,
  title,
  text,
  merit,
  badge,
  wrapMethod,
  method,
  equipment,
}
