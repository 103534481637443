import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'

const slider = css`
  bottom: -65vw;
  max-width: 510px;
  position: absolute;
  width: 100%;

  ${minMq('MD')} {
    bottom: 50%;
    height: 340px;
    position: absolute;
    right: 40px;
    transform: translateY(50%);
    width: 510px;
  }
`

export const style = {
  slider,
}
