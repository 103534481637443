import { Section } from '@/components/molecules/Heading/Section'
import { style } from './vr.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export const Vr = (): JSX.Element => {
  return (
    <div css={style.content} id="vr">
      <Section>
        <section>
          <SectionTitle title="VRコンテンツ" />

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-08.png`} alt="" />
                  <p>VRを使った工場見学を作りたい！</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>360°VR撮影で工場見学動画を作成。</p>
                  <p css={style.text}>
                    360°の動画撮影を用いた工場見学動画を作成いたします。VRで疑似体験ができるため、ユーザーに工場内の様子がしっかり伝わります。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-06.png`} alt="" />
                  <p>WEBサイトに施設の内観を取り入れたい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>撮影からコンテンツ作成・WEBサイトへの組み込みまで。</p>
                  <p css={style.text}>
                    360°VR撮影した画像を用いた内観コンテンツを作成いたします。普通の写真よりも全体の雰囲気を感じることができます。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="VRコンテンツを導入するメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                店舗や観光地の疑似体験が場所・時間を問わずにできる
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                自ら体を動かし、360度見渡すという能動的なアクションにより体験が記憶に残りやすい
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>360°VR撮影</span>
              </h4>
              <p>360°の静止画・動画の撮影ができます。4K、8Kの高解像度撮影も可能です。</p>

              <h4 css={style.method}>
                <span>VRコンテンツ作成</span>
              </h4>
              <p>
                撮影した360°静止画や動画を使用し、施設の内観・風景を体験できるVRコンテンツを作成します。
                <br />
                観光施設の紹介や工場見学、不動産のVR内観などに最適です。
              </p>

              <table css={style.equipment}>
                <tbody>
                  <tr>
                    <th>撮影エリア</th>
                    <td>主要エリア：新潟県内</td>
                  </tr>
                  <tr>
                    <th>撮影機材</th>
                    <td>Insta360 Pro</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="ギャラリー" />
            <div css={style.exampleList}>
              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-vr-01.png`}
                  alt={'レインボータワーのある景色'}
                />
                <p css={style.customer}>レインボータワーのある景色</p>
                <p css={style.exampleTitle}>VRコンテンツ</p>
              </div>

              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-vr-02.png`}
                  alt={'懐かしのレインボータワーに乗ってみよう'}
                />
                <p css={style.customer}>懐かしのレインボータワーに乗ってみよう</p>
                <p css={style.exampleTitle}>VRコンテンツ・動画</p>
              </div>

              <div css={style.exampleItem}>
                <img
                  src={`${BASE_PATH}/home/img/sample-vr-03.png`}
                  alt={'古くて新しい沼垂テラス商店街'}
                />
                <p css={style.customer}>古くて新しい沼垂テラス商店街</p>
                <p css={style.exampleTitle}>VRコンテンツ</p>
              </div>
            </div>

            <div css={style.linkGallery}>
              <a
                href="http://vrains.pressmedia.co.jp/contents/gallery_drone/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <span>ギャラリーサイトを見る</span>
              </a>
            </div>
          </div>
        </section>
      </Section>
    </div>
  )
}
