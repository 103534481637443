import { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BASE_PATH } from '@/config'
import { style } from './slider.style'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'

export const Slider = (): JSX.Element => {
  type swiperEffectType = 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative' | 'cards'
  const swiperEffect: swiperEffectType = 'fade'

  const params = {
    loop: true,
    modules: [Autoplay, EffectFade],
    spaceBetween: 50,
    fadeEffect: {
      crossFade: true,
    },
    effect: swiperEffect,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed: 1000,
  }

  return (
    <Swiper {...params} css={style.slider}>
      <SwiperSlide>
        <img src={`${BASE_PATH}/home/img/slide01.png`} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={`${BASE_PATH}/home/img/slide02.png`} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={`${BASE_PATH}/home/img/slide03.png`} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={`${BASE_PATH}/home/img/slide04.png`} alt="" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={`${BASE_PATH}/home/img/slide05.png`} alt="" />
      </SwiperSlide>
    </Swiper>
  )
}
