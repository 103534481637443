import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'
import variables from '@/styles/variables'

const content = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 1030px;
  padding: 20px;
  width: 100%;
`

const sectionSubTitle = css`
  color: ${variables.BASE_BDC};
  font-family: Yusei Magic, sans-serif;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 100%;

  ${minMq('MD')} {
    margin-bottom: 50px;
  }

  span {
    display: inline-block;
    font-weight: 400;
    position: relative;
    ${fontSize(22)}

    ${minMq('MD')} {
      ${fontSize(28)}
    }

    &::before,
    &::after {
      border-top: 4px solid ${variables.BASE_BDC};
      bottom: 30%;
      content: '';
      height: 0;
      position: absolute;
      width: 60px;
    }

    &::before {
      left: -50px;
      transform: rotate(60deg) skewX(-30deg);
    }

    &::after {
      right: -50px;
      transform: rotate(-60deg) skewX(30deg);
    }
  }
`

type Props = {
  title: string
}

export const SectionSubTitle = (props: Props): JSX.Element => {
  return (
    <header css={content}>
      <h3 css={sectionSubTitle}>
        <span>{props.title}</span>
      </h3>
    </header>
  )
}
