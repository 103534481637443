import { Link } from 'react-scroll'
import { Section } from '@/components/molecules/Heading/Section'
import { H } from '@/components/molecules/Heading/H'
import { style } from './works.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { BASE_PATH } from '@/config'

export const Works = (): JSX.Element => {
  return (
    <div css={style.content}>
      <Section>
        <section>
          <SectionTitle title="私たちがお手伝いできること" />
          <ul css={style.workList}>
            <li css={style.work}>
              <Link css={style.link} to="website" smooth={true}>
                <img
                  src={`${BASE_PATH}/home/img/work-web.png`}
                  alt="WEBサイト制作"
                  css={style.image}
                />
                <p css={style.title}>WEBサイト制作</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>
            <li css={style.work}>
              <Link css={style.link} to="movie" smooth={true}>
                <img
                  src={`${BASE_PATH}/home/img/work-movie.png`}
                  alt="動画制作"
                  css={style.image}
                />
                <p css={style.title}>動画制作</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>
            <li css={style.work}>
              <Link css={style.link} to="drone" smooth={true}>
                <img
                  src={`${BASE_PATH}/home/img/work-drone.png`}
                  alt="ドローン"
                  css={style.image}
                />
                <p css={style.title}>ドローン</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>

            <li css={style.work}>
              <Link css={style.link} to="vr" smooth={true}>
                <img src={`${BASE_PATH}/home/img/work-vr.png`} alt="360度・VR" css={style.image} />
                <p css={style.title}>360度・VR</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>
            <li css={style.work}>
              <Link css={style.link} to="cms" smooth={true}>
                <img src={`${BASE_PATH}/home/img/work-cms.png`} alt="CMS導入" css={style.image} />
                <p css={style.title}>CMS導入</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>
            <li css={style.work}>
              <Link css={style.link} to="printing" smooth={true}>
                <img
                  src={`${BASE_PATH}/home/img/work-print.png`}
                  alt="印刷物作成"
                  css={style.image}
                />
                <p css={style.title}>印刷物作成</p>
                <p css={style.detail}>詳しくはこちら</p>
              </Link>
            </li>
          </ul>

          <div css={style.boxCatch}>
            <Section>
              <H>
                サービス連携で
                <br data-mq="sp" />
                訴求力アップ！
              </H>
            </Section>

            <div css={style.worksCombination}>
              <div css={style.comment}>
                <p css={style.text}>
                  会社・お店からの情報発信をデジタルコンテンツ・印刷物などの組み合わせにより、訴求力のあるご提案をさせていただきます。
                </p>
                <img src={`${BASE_PATH}/home/img/works-combination.png`} alt="" />
              </div>

              <img
                src={`${BASE_PATH}/home/img/works-combination-fig1.png`}
                alt="サービス連携で訴求力アップ！"
                css={style.figure}
              />
            </div>
          </div>
        </section>
      </Section>
    </div>
  )
}
