import Link from 'next/link'
import { style } from './banner.style'
import { BASE_PATH } from '@/config'

export const Banner = (): JSX.Element => {
  return (
    <div css={style.content}>
      <Link href="/dlabindex">
        <a css={style.link}>
          <img
            src={`${BASE_PATH}/home/img/banner_dlab_pc.png`}
            alt="プレスメディアのデザイン研究開発"
          />
        </a>
      </Link>
    </div>
  )
}
