import { Section } from '@/components/molecules/Heading/Section'
import { style } from './webSite.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { WorkList } from '@/components/templates/Works/worksList'

export const WebSite = (): JSX.Element => {
  return (
    <div css={style.content} id="website">
      <Section>
        <section>
          <SectionTitle title="WEBサイト制作" />

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-01.png`} alt="" />
                  <p>
                    WEBサイトを作りたい。
                    <br />
                    でも何からすればいいのかわからない。
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>専門のスタッフがお見積りから納品まで対応いたします。</p>
                  <p css={style.text}>
                    WEBサイトの作成が初めてというお客様、当社にお任せください。WEBサイトの内容に関してはもちろん、立ち上げに必要な様々な手続きのバックアップも行います。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-02.png`} alt="" />
                  <p>
                    古くなったWEBサイトのリニューアルがしたい。
                    <br />
                    せっかくなら今時のデザインがいいな。
                  </p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>
                    デザインに加え、使いやすさにも考慮した WEBサイトを作ります。
                  </p>
                  <p css={style.text}>
                    WEBサイトは見た目の良さの他にも、ユーザーの使いやすさ、目的に合った機能などが重要となります。このような内容にも考慮し、WEBサイトの構成をご提案させていただきます。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-03.png`} alt="" />
                  <p>自社サイトの定期的な情報更新を代わりにやってほしい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>WEBサイトの更新を代行いたします。</p>
                  <p css={style.text}>
                    広報誌の公開やキャンペーンの告知など、定期的に更新が必要なWEBサイトの更新作業を代行いたします。
                    <br />
                    定期更新以外の対応も可能ですのでご相談ください。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-04.png`} alt="" />
                  <p>うちのWEBサイト、 どんな人が見に来ているのか知りたい。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>WEBサイトの分析・提案を行います。</p>
                  <p css={style.text}>
                    WEBサイトがどんなキーワードで検索されているか、媒体、地域などの情報を分析します。
                    <br />
                    分析の結果を元に、お客様の理想のWEBサイトに近づけるためのご提案を行います。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="WEBサイトを持つメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                ユーザーからの信頼感が得られる
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                ネット上で多くのユーザーの目に触れるため、集客の可能性が高まる
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                情報発信を行うことで営業ツール・販促ツールとしての機能を持つ
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                WEBサイトを解析することでマーケティングが可能
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                コスト削減
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>WEBサイト制作</span>
              </h4>
              <p>
                新規WEBサイトの立ち上げ、特設サイトの作成・WEBサイトのリニューアルなど、幅広く対応いたします。
              </p>

              <h4 css={style.method}>
                <span>WEBサイト更新代行</span>
              </h4>
              <p>
                お客様の代わりに、WEBサイトの情報更新を行います。当社作成のWEBサイトでなくてももちろんご対応いたします。
              </p>

              <h4 css={style.method}>
                <span>WEBサイトの分析・提案</span>
              </h4>
              <p>
                WEBサイト分析ツールを用いて現行WEBサイトの分析を行います。
                <br />
                お客様の目的達成に必要な改善点のご提案を行います。
              </p>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="制作事例" />
            <WorkList category="mjwd3ri30" />
          </div>
        </section>
      </Section>
    </div>
  )
}
