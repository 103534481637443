import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'

const content = css`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  max-width: 1030px;
  padding: 0 15px;

  ${minMq('MD')} {
    margin-top: 60px;
    width: 100%;
  }
`

const link = css`
  box-shadow: 0 0 2px 1px hsl(0deg 0% 67% / 40%);
  display: block;
  line-height: 0;
  width: 100%;

  ${minMq('MD')} {
    width: 100%;
  }

  & + a {
    margin-top: 30px;
  }
`

export const style = {
  content,
  link,
}
