import Page from '@/components/templates/Page'
import Content from '@/components/templates/Content'
import { Description } from '@/components/templates/Home/description'
import { Banner } from '@/components/templates/Home/banner'
import { Works } from '@/components/templates/Home/works'
import { WebSite } from '@/components/templates/Home/webSite'
import { Movie } from '@/components/templates/Home/movie'
import { Drone } from '@/components/templates/Home/drone'
import { Vr } from '@/components/templates/Home/vr'
import { Cms } from '@/components/templates/Home/cms'
import { Printing } from '@/components/templates/Home/printing'

const Home = (): JSX.Element => {
  const pageTitle = 'お客様の目的達成のお手伝いができます | 株式会社プレスメディア'

  return (
    <Page title={pageTitle}>
      <Content>
        <Description />
        <Banner />
        <Works />
        <WebSite />
        <Movie />
        <Drone />
        <Vr />
        <Cms />
        <Printing />
      </Content>
    </Page>
  )
}

export default Home
