import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import variables from '@/styles/variables'
import { fontSize } from '@/styles/mixin'

const wrapContent = css`
  padding: 40px 0;
`

const content = css`
  background-color: ${variables.SUB_C};
  padding: 20px 15px 55vw;

  ${minMq('MD')} {
    padding: 30px 0;
  }
`

const wrapSection = css`
  margin: 0 auto;

  position: relative;
  width: 100%;

  ${minMq('MD')} {
    width: 1030px;
  }
`

const catchcopy = css`
  width: 100%;

  ${minMq('MD')} {
    width: 420px;
  }

  h2 {
    ${fontSize(30)}

    ${minMq('MD')} {
      ${fontSize(45)}
    }
  }
`

const text = css`
  ${fontSize(14)}
  line-height: 1.6;
  margin-top: 1.5em;
`

export const style = {
  wrapContent,
  content,
  catchcopy,
  wrapSection,
  text,
}
