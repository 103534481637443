import { Section } from '@/components/molecules/Heading/Section'
import { style } from './movie.style'
import { SectionTitle } from '@/components/molecules/SectionTitle'
import { SectionSubTitle } from '@/components/molecules/SectionSubTitle'
import { BASE_PATH } from '@/config'
import { WorkList } from '@/components/templates/Works/worksList'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export const Movie = (): JSX.Element => {
  return (
    <div css={style.content} id="movie">
      <Section>
        <section>
          <SectionTitle title="動画制作" />

          <div css={[style.sectionBody, style.yellow]}>
            <SectionSubTitle title="このような課題が解決できます" />

            <div css={style.wrapCard}>
              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-05.png`} alt="" />
                  <p>ホームページに動画を載せたいけど動画を撮ったことがない。</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>
                    専門のスタッフが撮影・編集・納品までトータルで対応いたします。
                  </p>
                  <p css={style.text}>
                    当社の動画撮影部門・編集部門が、お客様の作りたい動画を全面サポートいたします。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-06.png`} alt="" />
                  <p>自分のスマートフォンで撮った動画をいい感じに編集してもらえる？</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>動画編集もお任せください。</p>
                  <p css={style.text}>
                    ご入稿いただいた動画を編集してお客様のご要望に合った内容に加工いたします。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-07.png`} alt="" />
                  <p>アニメーションみたいに 動く動画って作れますか？</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>
                    シンプルなイラストやピクトグラムを使用したアニメーションに対応可能です。
                  </p>
                  <p css={style.text}>
                    インフォグラフィックス（情報を視覚化して分かりやすく伝える表現方法）のアニメーション化も対応できます。広告動画やサービス紹介・イベント動画に利用できます。
                  </p>
                </div>
              </div>

              <div css={style.card}>
                <div css={style.problem}>
                  <img src={`${BASE_PATH}/home/img/problem-08.png`} alt="" />
                  <p>製品の使い方を伝える動画を作りたい！</p>
                </div>
                <div css={style.solve}>
                  <p css={style.title}>使い方に応じた撮影を行い、 製品の魅力や効果を伝えます。</p>
                  <p css={style.text}>
                    調理器具なら実際に調理をしているところ、被服なら身に付けた際の効果など、製品に合った場面を用意して動画を撮影いたします。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="動画を導入するメリット" />

            <ul css={style.merit}>
              <li>
                <CheckBadgeIcon css={style.badge} />
                音声と映像で情報量が多く、印象に残りやすい
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                商品の具体的な魅力が伝わりやすく、動画を見てから購入を検討する人も多い
              </li>
              <li>
                <CheckBadgeIcon css={style.badge} />
                SEOの効果が高く、検索エンジンからの評価が上がる
              </li>
            </ul>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="私たちができること" />
            <div css={style.wrapMethod}>
              <h4 css={style.method}>
                <span>動画撮影・編集</span>
              </h4>
              <p>
                当社の専門のスタッフが撮影に伺います。ドローンや360度カメラでの撮影も行えます。
                <br />
                当社で撮影した映像だけでなく、お客様が撮影した映像や保有している映像の編集も承ります。
              </p>

              <table css={style.equipment}>
                <tbody>
                  <tr>
                    <th>撮影機材</th>
                    <td>SONY α7RM4、DJI社 Inspire 2、DJI社 Phantom 4 Pro、Insta360 Pro</td>
                  </tr>
                </tbody>
              </table>

              <h4 css={style.method}>
                <span>アニメーションの作成</span>
              </h4>
              <p>
                シンプルなイラストやピクトグラムを使用した平面的なアニメーションを作成できます。
                <br />
                インフォグラフィックス（情報を視覚化して分かりやすく伝える表現方法）のアニメーション化も対応できます。
              </p>

              <h4 css={style.method}>
                <span>用途・活用例</span>
              </h4>
              <p>
                会社紹介／新卒・中途採用／商品説明／サービス紹介／観光案内／広告／展示会・イベント／マニュアル（HowTo）／インタビュー／施設・学校紹介／記念式典／説明会／株主総会など
              </p>
            </div>
          </div>

          <div css={style.sectionBody}>
            <SectionSubTitle title="制作事例" />
            <WorkList category="3hbiweylbwx" />
          </div>
        </section>
      </Section>
    </div>
  )
}
